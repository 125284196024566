import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import '../../ponents/header/app-header.js';
import '../../ponents/footer/app-footer.js';

import { styles } from './style.js';

@customElement('app-home')
export class AppHome extends LitElement {
  static styles = [styles];

  render() {
    return html`
      <app-header></app-header>
      <main>
        <div>
          <h1>
            "Providing a family-oriented home, for every child to thrive."
          </h1>
          <p>
            The Gems Family is an Alberta organization dedicated to providing
            positive life experiences and specialized care solutions to improve
            the individual development of children and youths experiencing
            trauma.
          </p>
          <a href="about-us"><button>Learn More</button></a>
        </div>
      </main>

      <article>
        <a href="/services"><h1>Our Services</h1></a>

        <ul>
          <li>
            <a href="/services"
              ><div>
                <img src="/assets/icons/icon-home.svg" />
                <h2>Hospitality & Social Wellness</h2>
              </div></a
            >
          </li>

          <li>
            <a href="/services"
              ><div class="card">
                <img src="/assets/icons/icon-heal.svg" />
                <h2>Spiritual Counselling</h2>
              </div>
            </a>
          </li>

          <li>
            <a href="/services"
              ><div class="card">
                <img src="/assets/icons/icon-skills.svg" />
                <h2>Life Skills & Strategies</h2>
              </div>
            </a>
          </li>

          <li>
            <a href="/services"
              ><div class="card">
                <img src="/assets/icons/icon-care.svg" />
                <h2>Nursing Care</h2>
              </div>
            </a>
          </li>

          <li>
            <a href="/services"
              ><div class="card">
                <img src="/assets/icons/icon-improve.svg" />
                <h2>Mental Health & Resilience</h2>
              </div>
            </a>
          </li>
        </ul>
      </article>

      <section>
        <div>
        <h1>A family-oriented home, for every child to thrive.</h1>
          <a href="/contact-us">
          <h2>
          Contact us today to learn more about our services and our
          committment to our clients.
          </h2>
          </a>
        </div>
      </section>
      <app-footer></app-footer>
    `;
  }
}
